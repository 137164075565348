import {
  Box,
  Center,
  Flex,
  Grid,
  Modal,
  PasswordInput,
  Text,
} from "@mantine/core";
import { KeyboardEventHandler, useState } from "react";
import { FilledButton } from "../components/filled-button";
import { InputText } from "../components/input-text";
import { OutlinedButton } from "../components/outlined-button";
import { signIn } from "next-auth/react";
import { useMessages } from "../hooks/messages";
import Image from "next/image";
import { useRouter } from "next/router";
import { useDisclosure } from "@mantine/hooks";
import { useAxios } from "../hooks/axios";
import { Colours } from "../colours";
import Head from "next/head";
import useMediaQuery from "../util/media-query";
import StDavidsLogo from "../components/StDavidsLogo";
import { useStDavidsDomain } from "../hooks/useClientDomain";

const Login = () => {
  const router = useRouter();
  const isStDavidsDomain = useStDavidsDomain();
  const isSmallScreen = useMediaQuery("(max-width: 845px)");
  const [email, setEmail] = useState("");
  const [forgotEmail, setForgotEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { showWarningMessage, showCompleteMessage } = useMessages();
  const [opened, { close, open }] = useDisclosure(false);
  const forgotPasswordRequest = useAxios({
    url: "/api/members/forgot-password",
    method: "post",
  });

  const onSubmit = () => {
    setLoading(true);
    signIn("credentials", {
      email: email.trim().toLowerCase(),
      password: password,
      redirect: false,
    })
      .then((res) => {
        if (typeof res !== "undefined") {
          const { error, ok } = res;
          if (!ok && typeof error !== "undefined") {
            showWarningMessage({
              customMessage: error || "",
              type: "custom",
              disabledCancel: true,
            });
          }
          if (ok) {
            showCompleteMessage({
              customMessage: true,
              assessmentName: "Successfully logged in.",
            });

            // After successful login, fetch the session to get user details
            fetch("/api/auth/session")
              .then((response) => response.json())
              .then((session) => {
                setTimeout(() => {
                  // Check if the user is a player, redirect to player dashboard
                  if (session.user && session.user.isPlayer) {
                    router.push(
                      `/player-dashboard?playerId=${session.user.id}`
                    );
                  } else {
                    // Regular user, redirect to dashboard
                    router.push("/dashboard");
                  }
                }, 1200);
              })
              .catch((err) => {
                console.error("Error fetching session", err);
                // Default to regular dashboard if session fetch fails
                setTimeout(() => {
                  router.push("/dashboard");
                }, 1200);
              });
          }
        }
      })
      .catch((err) => {
        console.error("error", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const listenToEnterPress: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (email.length === 0 || password.length === 0) return;
    if (event.keyCode === 13) onSubmit();
  };

  const headerText = () => {
    if (isStDavidsDomain)
      return "Welcome to St David's Marist Inanda's Athlete Mangement System";
    return "Welcome to Medicoach's Athlete Mangement System";
  };

  return (
    <>
      <Head>
        <title>Log In</title>
      </Head>
      <Modal
        opened={opened}
        onClose={() => {
          setForgotEmail("");
          close();
        }}
        centered
        title={"Forgot your password"}
        styles={{ modal: { width: "300px !important", borderRadius: "15px" } }}
      >
        <Center>
          <Flex direction="column">
            <InputText
              value={forgotEmail}
              placeholder="Email address"
              inputStyle={{
                fontSize: "14px",
                width: "220px",
                marginTop: "10px",
              }}
              onChange={(event) => setForgotEmail(event.target.value)}
            />
            <FilledButton
              text="Send email"
              disabled={forgotEmail.length === 0}
              loading={forgotPasswordRequest.loading}
              onClick={() =>
                forgotPasswordRequest.execute({ email: forgotEmail })
              }
              sx={{ marginLeft: "32px" }}
            />
          </Flex>
        </Center>
      </Modal>
      <Grid sx={{ margin: 0 }}>
        <Grid.Col span={isSmallScreen ? 12 : 6} sx={{ padding: 0 }}>
          <Box
            onKeyDown={listenToEnterPress}
            sx={{
              width: "100%",
              height: "100vh",
              padding: isSmallScreen ? "50px" : "100px 140px 180px 140px",
            }}
          >
            <Text sx={{ fontSize: "32px", fontWeight: 500 }}>Log in</Text>
            <Flex align="center">
              <Image
                alt="MediCoach logo"
                src="/medicoach-blue-logo.png"
                width={200}
                height={70}
              />
              <StDavidsLogo
                imageProps={{
                  width: 150,
                  // height: 60,
                  style: { height: "auto" },
                }}
              />
            </Flex>
            <Text sx={{ fontSize: "20px", fontWeight: 500, marginTop: "20px" }}>
              {headerText()}
            </Text>
            <Box sx={{ marginTop: "30px" }}>
              <InputText
                placeholder="E-mail"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                inputStyle={{
                  width: "100%",
                  height: "40px",
                  borderRadius: "10px",
                  border: "1px solid",
                  borderColor: Colours.background,
                  ":focus": {
                    borderColor: Colours.darkBlue,
                  },
                }}
                styles={{
                  wrapper: { borderColor: "red" },
                }}
              />
              <PasswordInput
                placeholder="Password"
                value={password}
                // type="password"
                onChange={(event) => setPassword(event.target.value)}
                sx={{
                  height: "40px",
                }}
                styles={{
                  input: {
                    width: "100%",
                    height: "40px",
                    borderRadius: "10px",
                    marginTop: "10px",
                    backgroundColor: Colours.background,
                    borderColor: Colours.background,
                    ":focus-within": {
                      borderColor: Colours.darkBlue,
                    },
                  },
                  innerInput: {
                    fontSize: "12px",
                  },
                  root: {
                    borderColor: "red",
                  },
                }}
              />
              <OutlinedButton
                text="Forgot password"
                onClick={() => open()}
                sx={{
                  color: "#3F8D63",
                  fontSize: "12px",
                  fontWeight: 500,
                  marginTop: "15px",
                  width: "145px",
                  paddingLeft: 0,
                  border: "none",
                }}
              />
            </Box>
            <FilledButton
              loading={loading}
              text="Log in"
              disabled={email.length === 0 || password.length === 0}
              sx={{ width: "100%", marginTop: "30px", borderRadius: "5px" }}
              onClick={onSubmit}
            />
          </Box>
        </Grid.Col>
        {!isSmallScreen && (
          <Grid.Col
            span={6}
            sx={{
              padding: 0,
              span: {
                width: "100% !important",
                height: "100% !important",
                maxHeight: "100vh!important",
              },
              position: "relative",
            }}
          >
            <Image
              alt="Log in image of athlete"
              src="/images/Athlete.jpg"
              layout="fill"
              objectFit="cover"
            />
          </Grid.Col>
        )}
      </Grid>
    </>
  );
};

export default Login;
