import { CSSObject, TextInput, TextInputProps } from "@mantine/core";
import { ChangeEvent, ReactNode } from "react";
import { Colours } from "../colours";

/**
 * InputText properties and their types
 */
export type InputTextProps = {
  placeholder?: string;
  value?: string | number | readonly string[];
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  inputStyle?: CSSObject;
  leftIcon?: ReactNode | any;
  rightIcon?: ReactNode | any;
} & TextInputProps;

/**
 * InputText component to input text
 * @param placeholder - Placeholder for the input textbox
 * @param value - Value of the input
 * @param onChange - function to invoke when typing in the input
 * @param sx - the styles of the input in a CSSObject format
 * @param type - type of input: number, string, etc.
 * @param leftIcon - left icon
 * @param rightIcon - right icon
 * @returns returns a styled Mantine TextInput
 */
export const InputText = ({
  placeholder,
  value,
  onChange,
  inputStyle,
  type,
  leftIcon,
  rightIcon,
  styles,
  ...props
}: InputTextProps) => {
  return (
    <TextInput
      placeholder={placeholder}
      styles={{
        input: {
          fontSize: "12px",
          backgroundColor: Colours.background,
          border: "none",
          width: "200px",
          borderRadius: "10px",
          ...inputStyle,
        },
        ...styles,
      }}
      icon={leftIcon}
      rightSection={rightIcon}
      type={type}
      value={value}
      onChange={onChange}
      size="xs"
      {...props}
    />
  );
};
