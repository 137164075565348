import { showNotification } from "@mantine/notifications";
import { openContextModal } from "@mantine/modals";

type ShowCompleteMessageParameters = {
  customMessage?: boolean;
  assessmentName: string;
  onClick?: () => void;
  autoClose?: number;
};

type ShowWarningMessageParameters = {
  type: "toggle" | "leaving page" | "error" | "custom";
  customMessage?: string;
  onCancel?: () => void;
  onContinue?: () => void;
  disabledCancel?: boolean;
};

/**
 *  Sets the message appropriately
 * @param type of the message to be displayed
 * @param customMessage if there is additional text to showcase
 * @returns the message
 */
const setWarningMessage = (
  type: ShowWarningMessageParameters["type"],
  customMessage?: string
) => {
  if (type === "toggle")
    return "You are about to untoggle this section which will remove any data inputted. Do you want to continue with this action?";
  else if (type === "leaving page")
    return "You are about to leave this page without saving your progress. Your data will be lost.";
  else if (type === "error") return `${customMessage}`;
  return customMessage as string;
};

/**
 *  Used for showing notification messages to the user
 *
 * @returns
 */
export const useMessages = () => {
  /**
   * Shown when an assessment has been completed and saved
   * @param customMessage if a custom message should be shown instead of default. The message should be supplied in the assessmentName parameter
   * @param assessmentName name of assesment that has been complete
   * @param onClick when Ok button is clicked
   * @returns
   */
  const showCompleteMessage = ({
    customMessage = false,
    assessmentName,
    onClick,
    autoClose = 1200,
  }: ShowCompleteMessageParameters) =>
    showNotification({
      message: customMessage
        ? assessmentName
        : `${assessmentName} has been completed.`,
      sx: { backgroundColor: "#1F6521", color: "white" },
      styles: {
        root: {
          "&::before": { backgroundColor: "#1F6521" },
          width: "100%",
          paddingRight: "10px",
          borderRadius: "10px",
        },
        description: { color: "white", fontSize: "16px" },
        closeButton: {
          "& > svg": {
            display: "none",
            ":hover": { backgroundColor: "transparent !important" },
          },
        },
      },
      // closeButtonProps: {
      //   children: "Ok",
      //   sx: { textTransfrom: "none" },
      // },
      onClose: onClick,
      autoClose,
      radius: "md",
      color: "#1F6521",
    });

  /**
   *  Displays the warning model when either untoggling or leaving the page while there is data saved or an error occurred on the server
   * @param type of the message
   * @param customMessage if a custom message should be shown
   * @returns
   */
  const showWarningMessage = ({
    type,
    customMessage,
    onCancel,
    onContinue,
    disabledCancel,
  }: ShowWarningMessageParameters) =>
    openContextModal({
      modal: "warning",
      innerProps: {
        message: setWarningMessage(type, customMessage),
        onCancel,
        onContinue,
        disabledCancel: disabledCancel || false,
      },
      centered: true,
      closeOnClickOutside: false,
      closeOnEscape: false,
      styles: {
        header: { display: "none" },
        modal: { borderRadius: "20px" },
      },
    });

  return { showCompleteMessage, showWarningMessage };
};
