import { useState, useEffect } from "react";

/**
 * A hook that safely checks if a specific domain is present in the current URL
 * Works safely with server-side rendering by only checking after hydration
 *
 * @param domainToCheck The domain substring to check for
 * @returns boolean indicating if the domain is present (false during SSR)
 */
export function useClientDomain(domainToCheck: string): boolean {
  const [isDomainPresent, setIsDomainPresent] = useState(false);

  useEffect(() => {
    // This only runs on the client after hydration
    if (typeof window !== "undefined") {
      setIsDomainPresent(window.location.href.includes(domainToCheck));
    }
  }, [domainToCheck]);

  return isDomainPresent;
}

export function useStDavidsDomain(): boolean {
  return useClientDomain("stdavids");
  // return useClientDomain("local");
}
