import { useEffect, useState } from "react";
import axios, { Method, AxiosResponse, ResponseType, AxiosError } from "axios";
import { useRouter } from "next/router";
import { useMessages } from "./messages";

export interface AxiosHookInput {
  url: string;
  method: Method;
  responseType?: ResponseType;
}

/**
 * Checks whether url has /api. If not adds, otherwise just returns the same url
 * */
const setUrl = (url: string) => {
  if (url.includes("api")) return url;
  return `/api/${url}`;
};

// Used for network requests
export const useAxios = <Data = any>({
  url,
  method,
  responseType,
}: AxiosHookInput) => {
  const { showWarningMessage } = useMessages();
  const [response, setResponse] = useState<
    AxiosResponse<Data> | Record<string, any>
  >({});
  const [error, setError] = useState<
    AxiosError<{ message: string } | Record<string, any>> | Record<string, any>
  >({});
  const [loading, setLoading] = useState<boolean>(false);
  const router = useRouter();

  useEffect(() => {
    // Redirect on authorisation error
    if (Object.keys(error).length > 0) {
      if (error.status === 401) {
        router.push("/login");
        return;
      }
      showWarningMessage({
        disabledCancel: true,
        type: "error",
        customMessage:
          error?.response && error?.response.data
            ? error?.response.data.message || ""
            : "",
        onContinue: () => { },
      });
    }
  }, [error]);

  const execute = async (payload: Record<string, any>, newUrl?: string) => {
    // reset state
    setLoading(true);
    setError({});
    setResponse({});

    try {
      const result = await axios.request({
        url: setUrl(newUrl || url),
        method,
        data: payload,
        responseType,
        withCredentials: true,
      });
      setResponse(result);
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && typeof error.response !== "undefined") {
        setError(error.response);
      }
      setError(error as Record<string, any>);
    } finally {
      setLoading(false);
    }
  };

  const succeeded = () => {
    return Object.keys(error).length === 0 && Object.keys(response).length > 0;
  };

  const hasResponse = () => {
    return Object.keys(response).length > 0;
  };

  const hasError = () => {
    return Object.keys(error).length > 0;
  };

  return {
    response,
    error,
    loading,
    hasError,
    hasResponse,
    succeeded,
    execute,
  };
};
