import { ButtonProps } from "@mantine/core";
import { MouseEventHandler, ReactNode } from "react";
import { GenericButton } from "./generic-button";

type FilledButtonProps = {
  disabled?: boolean;
  loading?: boolean;
  sx?: ButtonProps["sx"];
  onClick?: MouseEventHandler<HTMLButtonElement>;
  text?: string;
  children?: ReactNode;
};

/** Button used to skip a form or continue after adata has been entered in a form
 *
 * @param disabled if button is disabled
 * @param loading if the button triggers some action that is being processed
 * @param sx styling for button
 * @param onClick function called when click event occurs
 * @param text for the button
 * @param children any child components
 * @returns
 */
export const FilledButton = ({
  disabled,
  loading = false,
  sx,
  onClick,
  text,
  children,
}: FilledButtonProps) => {
  return (
    <GenericButton
      text={text || "Save"}
      onClick={onClick}
      sx={{
        marginTop: "20px",
        ...sx,
      }}
      disabled={disabled}
      loading={loading}
    >
      {children}
    </GenericButton>
  );
};
