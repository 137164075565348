import { Image } from "@mantine/core";
import { useClientDomain } from "../hooks/useClientDomain";

type StDavidsLogoProps = {
  width?: number;
  style?: React.CSSProperties;
  onClick?: () => void;
  alt?: string;
  imageProps?: any;
};

export default function StDavidsLogo({
  width = 150,
  style,
  onClick,
  alt = "St Davids logo",
  imageProps = {},
}: StDavidsLogoProps) {
  const isStDavidsDomain = useClientDomain("stdavids");
  // const isStDavidsDomain = useClientDomain("local");

  if (!isStDavidsDomain) return null;

  return (
    <Image
      width={width}
      src="/images/stdavids.png"
      style={{
        marginLeft: "10px",
        ...style,
      }}
      onClick={onClick}
      alt={alt}
      {...imageProps}
    />
  );
}
